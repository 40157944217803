import React from 'react';

export const API_ROOT = 'https://api.n2.zig-zag.org';

const queryApiUrl = async (token, url, query) => {
    const q = {...query};
    if (token)
        q.headers = {...q.headers, 'Authorization': `JWT ${token}`};
    const response = await fetch(`${API_ROOT}/${url}`, q);
    return await response.text();
};

const queryApiMethod = async (token, query) => {
    const r = await queryApiUrl(token, 'graphql', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(query)
    });
    const res = await JSON.parse(r);
    if (res.data)
      return res.data.result;
    throw new Error(res.message);
};

const Ctx = React.createContext({state: {}, actions: {}});

export
class DataProvider extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('token'),
            user: null,
            authenticating: false,
            loading: false,
            organizations: []
        }
    }

    async componentWillMount() {
        if (this.state.token)
            await this.actions.loadUser();
    }

    render() {
        return (
            <Ctx.Provider value={{ state: this.state, actions: this.actions }}>
                {this.props.children}
            </Ctx.Provider>
        );
    }

    actions = {
        loadUser: async () => {
            this.setState({authenticating: true});
            try {
                const user = await queryApiMethod(this.state.token, {operationName: "CURRENT_USER"});
                this.setState({user: user, authenticating: false});
            }
            catch (e) {
                console.log(e);
                this.setState({user: null, authenticating: false});
            }
        },

        loginUser: async (login, password) => {
            this.setState({authenticating: true});
            try {
                const r = await queryApiUrl(null, 'login', {
                    method: 'POST',
                    headers: {
                       'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({username: login, password: password})
                });
                const res = JSON.parse(r);
                if (!res.result)
                    throw new Error(res.message);
                const user = await queryApiMethod(res.token, {operationName: "CURRENT_USER"});
                localStorage.setItem('token', res.token);
                this.setState({token: res.token, user: res.user, authenticating: false});
            }
            catch (e) {
                console.log(e);
                this.setState({token: null, user: null, authenticating: false});
            }
        },

        logoutUser: () => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            this.setState({user: null, token: null});
        },

        loadOrganizations: async () => {
            this.setState({loading: true});
            try {
                const org = await queryApiMethod(this.state.token, {operationName: "ORGANIZATIONS"});
                this.setState({organizations: org, loading: false});
            }
            catch (e) {
                console.log(e);
                this.setState({loading: false});
            }
        },

        loadOrganization: async (id) => {
            try {
                const org = await queryApiMethod(this.state.token,
                    {operationName: "ORGANIZATION", "variables": {"id": id}});
                return org;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        loadUsageStats: async (license_token) => {
            try {
                const res = await queryApiMethod(this.state.token,
                    {operationName: "ORGANIZATION_USAGE_STATS", "variables": {"license_token": license_token}});
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        saveOrganization: async (orgId, changes) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "UPDATE_ORGANIZATION",
                    "variables": {id: orgId, "data": changes}
                });
                const newOrgs = [...this.state.organizations];
                for (let i = 0; i < newOrgs.length; ++i)
                    if (newOrgs[i].id === orgId) {
                        newOrgs[i] = res;
                        break;
                    }
                this.setState({organizations: newOrgs});
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        registerOrganization: async (data) => {
            try {
                const r = await queryApiUrl(null, 'registration', {
                    method: 'POST',
                    headers: {
                       'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                const res = await JSON.parse(r);
                if (!res.result)
                    throw new Error(res.message);
                const org = await queryApiMethod(this.state.token, {operationName: "ORGANIZATION", variables: {id: res.user.org_id} });
                const newOrgs = [...this.state.organizations, org];
                this.setState({organizations: newOrgs});
                return org;
            }
            catch (e) {
                console.log(e);
                //this.setState({token: null, user: null, authenticating: false});
                throw e;
            }
        },

        loadUsers: async (orgId) => {
            try {
                const res = await queryApiMethod(this.state.token, {operationName: "USERS_ADMIN", "variables": {"org_id": orgId}});
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        findUsers: async (filter) => {
            try {
                const res = await queryApiMethod(this.state.token, {operationName: "FIND_USER", "variables": {"filter": filter}});
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        getUserAuthToken: async (userId) => {
            try {
                const res = await queryApiMethod(this.state.token, {operationName: "GET_USER_AUTH_TOKEN", "variables": {"id": userId}});
                return res.token;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        refreshUserToken: async (userId) => {
            try {
                const res = await queryApiMethod(this.state.token, {operationName: "REFRESH_USER_AUTH_TOKEN", "variables": {"id": userId}});
                return res.token;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        saveUser: async (userId, orgId, changes) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "UPDATE_USER",
                    "variables": {"id": userId, "org_id": orgId, "data": changes}
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        createUser: async (orgId, changes) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "CREATE_USER",
                    "variables": {"org_id": orgId, "data": changes}
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        loadReports: async (orgId) => {
            try {
                const res = await queryApiMethod(this.state.token, {operationName: "REPORTS_ADMIN", variables: {org_id: orgId}});
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        saveReport: async (reportId, orgId, changes) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "UPDATE_REPORT",
                    "variables": {"id": reportId, "org_id": orgId, "data": changes}
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        createReport: async (orgId, changes) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "CREATE_REPORT",
                    "variables": {"org_id": orgId, "data": changes}
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        getReportTemplate: async (reportId, orgId) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "DOWNLOAD_TEMPLATE_BASE64",
                    "variables": {"id": reportId, "org_id": orgId}
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        testReportQuery: async (reportId, orgId, params) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "TEST_REPORT_QUERY",
                    "variables": {"id": reportId, "org_id": orgId, params: params}
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        testReport: async (reportId, orgId, params, format) => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "GENERATE_REPORT",
                    "variables": {"id": reportId, "org_id": orgId, params: params, format: format}
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        getOptimizationQueueStatus: async () => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "OPTIMIZATION_QUEUE_STATUS",
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },

        getYandexStatus: async () => {
            try {
                const res = await queryApiMethod(this.state.token, {
                    operationName: "TEST_YANDEX_GEOCODE",
                });
                return res;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        }
    }
}

export
const withDataProvider = (Component) => {
    return (props) => (
        <Ctx.Consumer>
            {value => <Component {...props} state={value.state} actions={value.actions}></Component>}
        </Ctx.Consumer>
    )
}

export
const DataConsumer = Ctx.Consumer;